<ui-popover
  #popover="ui-popover"
  [config]="POPOVER_CONFIG">
  <ng-template ui-popover-template>
    <div class="wrapper">
      <ns-popover-header />
      <ns-notifications-list />
      <!-- TODO: Check with UX if we want to keep this or not -->
      <!-- <ns-popover-footer /> -->
    </div>
  </ng-template>
</ui-popover>
