<ui-popover
  #popover="ui-popover"
  [config]="POPOVER_CONFIG">
  <ng-template ui-popover-template>
    <div
      class="wrapper"
      (mouseenter)="mouseEntered()"
      (mouseleave)="mouseLeft()">
      @let _notification = notification();
      @if (_notification) {
        <ns-notification-item [notification]="_notification" />
      }
    </div>
  </ng-template>
</ui-popover>
