@let _notification = notification();
<div
  class="wrapper"
  tabindex="0"
  (keypress.enter)="openLink()"
  (keypress.space)="openLink()"
  (click)="openLink()">
  <span class="title">
    <strong>
      {{ title() }}
    </strong>
    <span
      class="time-since"
      [uiTooltip]="createdTime()">
      {{ timeSince() }}
    </span>
  </span>
  <span class="message">
    {{ message() }}
    @let _link = link();
    @if (_link) {
      <a
        target="_blank"
        href="_link"
        >{{ linkCaption() ?? _link }}</a
      >
    }
  </span>
  @let _creativeSetName = creativeSetName();
  @if (_creativeSetName) {
    <span class="creativeset-name">
      {{ _creativeSetName }}
    </span>
  }
  <div
    class="read-status-marker {{ _notification.type }}"
    tabindex="0"
    [uiTooltip]="'Mark as read'"
    (keypress.enter)="markAsRead($event)"
    (keypress.space)="markAsRead($event)"
    (click)="markAsRead($event)">
    <div
      class="marker"
      [class.unread]="!_notification.read"></div>
  </div>
</div>
