import { Component, computed, inject, input } from '@angular/core';
import {
  NotificationDto,
  NotificationType
} from '@bannerflow/types.notification-service';
import { UITooltipDirective } from '@bannerflow/ui';
import { NotificationService } from '../../../services/notification.service';
import {
  getImageExportMessage,
  getImageExportTitle,
  getVideoExportMessage,
  getVideoExportTitle,
  isImageExportNotification,
  isVideoExportNotification
} from '../../../utils';
import { timeSince } from '../../../utils/time.utils';

@Component({
  imports: [UITooltipDirective],
  selector: 'ns-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss'
})
export class NotificationItemComponent {
  private notificationService = inject(NotificationService);

  notification = input.required<NotificationDto>();

  createdTime = computed(() => this.computeCreatedTime());
  creativeSetName = computed(() => this.computeCreativeSetName());
  link = computed(() => this.computeLink());
  linkCaption = computed(() => this.computeLinkCaption());
  message = computed(() => this.computeMessage());
  timeSince = computed(() => this.computeTimeSince());
  title = computed(() => this.computeTitle());

  openLink(): void {
    const notification = this.notification();
    if (!notification.content.link) {
      return;
    }
    if (
      isVideoExportNotification(notification) ||
      isImageExportNotification(notification)
    ) {
      this.downloadLink(notification.content.link);
    } else {
      this.openLinkInNewTab(notification.content.link);
    }
  }

  markAsRead(event: Event): void {
    event.stopPropagation();
    const notification = this.notification();
    this.notificationService.markAsRead(notification);
  }

  private computeTitle(): string {
    const notification = this.notification();
    if (isVideoExportNotification(notification)) {
      return getVideoExportTitle(notification);
    } else if (isImageExportNotification(notification)) {
      return getImageExportTitle(notification);
    }
    return notification.content.title ?? '';
  }

  private computeMessage(): string {
    const notification = this.notification();
    if (isVideoExportNotification(notification)) {
      return getVideoExportMessage(notification);
    } else if (isImageExportNotification(notification)) {
      return getImageExportMessage(notification);
    }
    return notification.content.message ?? '';
  }

  private computeCreativeSetName(): string {
    const notification = this.notification();
    if (
      isVideoExportNotification(notification) ||
      isImageExportNotification(notification)
    ) {
      return notification.content.metadata.creativeSetName;
    }
    return notification.content.metadata?.['creativeSetName'] ?? '';
  }

  private computeLink(): string | undefined {
    const notification = this.notification();
    if (
      isVideoExportNotification(notification) ||
      isImageExportNotification(notification)
    ) {
      if (notification.type === NotificationType.Success) {
        return notification.content.link ?? '';
      } else if (notification.type === NotificationType.Error) {
        return;
      }
    }
    return;
  }

  private computeLinkCaption(): string | undefined {
    const notification = this.notification();
    if (
      isVideoExportNotification(notification) ||
      isImageExportNotification(notification)
    ) {
      if (notification.type === NotificationType.Success) {
        return 'Download';
      } else if (notification.type === NotificationType.Error) {
        return 'More info';
      }
    }
    return;
  }

  private computeTimeSince(): string {
    const notification = this.notification();

    const date = new Date(notification.created);
    return timeSince(date);
  }

  private computeCreatedTime(): string {
    const notification = this.notification();
    const date = new Date(notification.created);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    return date.toLocaleString(undefined, options);
  }

  private openLinkInNewTab(link: string): void {
    window.open(link, '_blank')?.focus();
  }

  private downloadLink(link: string): void {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = link;
    document.body.appendChild(a);
    a.click();
  }
}
