import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import {
  NS_CONFIG_TOKEN,
  NSNotificationModuleOptions
} from './notification.types';
import { NotificationService } from './services/notification.service';
import { NotificationDataService } from './services/notification.data.service';
import { SignalRService } from './services/signalr.service';
import { UserService } from './services/user.service';

export function provideNotificationService(
  configs: NSNotificationModuleOptions
): EnvironmentProviders {
  return makeEnvironmentProviders([
    NotificationDataService,
    NotificationService,
    SignalRService,
    UserService,
    { provide: NS_CONFIG_TOKEN, useValue: configs }
  ]);
}
