import {
  NotificationDto,
  TopicType
} from '@bannerflow/types.notification-service';
import { ImageExportNotification, VideoExportNotification } from '../domain';

export function isVideoExportNotification(
  notification: NotificationDto
): notification is VideoExportNotification {
  return notification.topic === TopicType.VideoExport;
}

export function isImageExportNotification(
  notification: NotificationDto
): notification is ImageExportNotification {
  return notification.topic === TopicType.ImageExport;
}
