import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { NS_CONFIG_TOKEN } from '../notification.types';

@Injectable()
export class UserService {
  private readonly config = inject(NS_CONFIG_TOKEN);

  userId = toSignal(this.config.userId$ ?? new BehaviorSubject(undefined));
}
