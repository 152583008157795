import { Component, input, viewChild } from '@angular/core';
import { NotificationDto } from '@bannerflow/types.notification-service';
import {
  UIModule,
  UIPopoverDirective,
  UIPopoverRef,
  UIPopoverTargetDirective
} from '@bannerflow/ui';
import { POPOVER_CONFIG } from '../../../constants';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

const INITIAL_DELAY = 3000;
const MOUSELEAVE_DELAY = 800;

@Component({
  imports: [UIModule, NotificationItemComponent],
  selector: 'ns-quick-popover',
  templateUrl: './quick-popover.component.html',
  styleUrl: './quick-popover.component.scss'
})
export class QuickPopoverComponent {
  notification = input<NotificationDto | undefined>();

  private popover = viewChild.required<UIPopoverDirective>('popover');
  private beingHovered = false;
  private popoverRef: UIPopoverRef | undefined;
  POPOVER_CONFIG = POPOVER_CONFIG;

  openPopover(target: UIPopoverTargetDirective): UIPopoverRef {
    const popover = this.popover();
    if (!popover) {
      throw new Error('Could not find popover child');
    }
    this.popoverRef = popover.open(target);

    setTimeout(() => {
      this.closePopover();
    }, INITIAL_DELAY);
    return this.popoverRef;
  }

  mouseEntered(): void {
    this.beingHovered = true;
  }

  mouseLeft(): void {
    this.beingHovered = false;
    setTimeout(() => {
      this.closePopover();
    }, MOUSELEAVE_DELAY);
  }

  private closePopover(): void {
    if (!this.popoverRef || this.beingHovered) {
      return;
    }

    this.popoverRef.close();
  }
}
