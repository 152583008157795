// taken from: https://stackoverflow.com/a/3177838/18698724
export function timeSince(date: Date): string {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    interval = Math.floor(interval);
    return interval === 1 ? '1 year ago' : `${interval} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    interval = Math.floor(interval);
    return interval === 1 ? '1 month ago' : `${interval} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    interval = Math.floor(interval);
    return interval === 1 ? '1 day ago' : `${interval} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    interval = Math.floor(interval);
    return interval === 1 ? '1 hour ago' : `${interval} hours ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    interval = Math.floor(interval);
    return interval === 1 ? ` 1 minute ago` : `${interval} minutes ago`;
  }
  // TODO: Decided if this is needed or not: return `${Math.floor(seconds)} seconds ago`;
  return 'Just now';
}
