import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

const allowedList = [
  ...Object.values(environment.origins).map(url => `${url}/*`),
  `${environment.NSSignalR.url}/*`
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    provideAuth0({
      domain: environment.auth0.loginUrl,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/${window.location.search}`,
        audience: 'https://bannerflow.com/resources/',
        scope: 'openid profile email offline_access'
      },
      httpInterceptor: {
        allowedList: allowedList
      },
      useRefreshTokens: true
    }),
    provideAnimationsAsync(), // Needed for @bannerflow/ui
    provideNotificationService({
      apiUri: environment.origins['notificationServiceApi'],
      userId$: new BehaviorSubject<string | undefined>(undefined),
      accessToken$: new BehaviorSubject<string | undefined>(undefined),
      useTestData: window.location.search.includes('useTestData=true'),
      signalR: {
        enabled: environment.NSSignalR.enabled,
        url: environment.NSSignalR.url
      }
    })
  ]
};
