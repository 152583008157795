<div class="wrapper">
  <h2>Notifications</h2>
  <div class="actions">
    <a
      class="mark-all-as-read"
      (click)="markAllAsRead()">
      Mark all as read
    </a>
    <ui-svg-icon
      icon="kebab"
      [uiDropdownTarget]="menu" />
    <ui-dropdown
      #menu
      style="display: none">
      @if (isShowingAll()) {
        <ui-dropdown-item
          id="show-unread"
          (click)="showUnreadClicked()">
          Show only unread
        </ui-dropdown-item>
      } @else {
        <ui-dropdown-item
          id="show-all"
          (click)="showAllClicked()">
          Show all
        </ui-dropdown-item>
      }
    </ui-dropdown>
  </div>
</div>
