<div
  class="wrapper"
  ui-popover-target
  #notificationPopoverTarget="ui-popover-target">
  <div
    class="button"
    [class.disabled]="disabled()"
    tabindex="0"
    (keypress.enter)="bellClicked()"
    (keypress.space)="bellClicked()"
    (click)="bellClicked()">
    <ui-svg-icon
      class="notification-icon"
      icon="notification" />

    @if (hasUnreadNotifications()) {
      <div class="unread-count"></div>
    }
  </div>
</div>
<ns-notification-popover #notificationPopover />
<ns-quick-popover
  #quickPopover
  [notification]="newNotification()" />
