import {
  AfterViewInit,
  Component,
  effect,
  inject,
  input,
  OnInit,
  viewChild
} from '@angular/core';
import { UIModule, UIPopoverTargetDirective } from '@bannerflow/ui';
import { NotificationService } from '../../services/notification.service';
import { SignalRService } from '../../services/signalr.service';
import { NotificationPopoverComponent } from './notification-popover/notification-popover.component';
import { QuickPopoverComponent } from './quick-popover/quick-popover.component';

@Component({
  imports: [UIModule, NotificationPopoverComponent, QuickPopoverComponent],
  selector: 'ns-notifications-component',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NSNotificationsComponent implements OnInit, AfterViewInit {
  private notificationsService = inject(NotificationService);
  private signalRService = inject(SignalRService);

  private notificationPopoverTarget =
    viewChild.required<UIPopoverTargetDirective>('notificationPopoverTarget');
  private notificationPopover =
    viewChild.required<NotificationPopoverComponent>('notificationPopover');
  private quickPopover =
    viewChild.required<QuickPopoverComponent>('quickPopover');

  disabled = input<boolean>();

  hasUnreadNotifications = this.notificationsService.hasUnreadNotifications;

  newNotification = this.notificationsService.newNotification;

  constructor() {
    effect(
      () => {
        const newNotification = this.signalRService.newNotification();
        if (newNotification) {
          this.notificationsService.loadNumberOfUnreadNotifications();
          this.notificationsService.loadAndPrependNotification(newNotification);
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.effectShowQuickPopover();
    });
  }

  ngOnInit(): void {
    this.notificationsService.loadNumberOfUnreadNotifications();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.signalRService.startConnection();
  }

  bellClicked(): void {
    if (this.disabled()) {
      return;
    }
    const notificationPopover = this.notificationPopover();
    if (!notificationPopover) {
      return;
    }
    const target = this.notificationPopoverTarget();
    if (!target) {
      return;
    }
    notificationPopover.openPopover(target);
  }

  private effectShowQuickPopover(): void {
    const newNotification = this.newNotification();
    if (!newNotification) {
      return;
    }
    if (this.disabled()) {
      return;
    }
    const quickPopover = this.quickPopover();
    if (!quickPopover) {
      return;
    }
    const target = this.notificationPopoverTarget();
    if (!target) {
      return;
    }
    quickPopover.openPopover(target);
  }
}
