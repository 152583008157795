import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

export const NS_CONFIG_TOKEN = new InjectionToken<NSNotificationModuleOptions>(
  'ns_config'
);

export interface NSNotificationModuleOptions {
  apiUri: string;
  userId$?: Subject<string | undefined>;
  accessToken$: Subject<string | undefined>;
  useTestData?: boolean;
  signalR: {
    enabled: boolean;
    url: string;
  };
}
