import { NotificationType } from '@bannerflow/types.notification-service';
import { ImageExportNotification, VideoExportNotification } from '../domain';

export function getVideoExportTitle(
  notification: VideoExportNotification
): string {
  switch (notification.type) {
    case NotificationType.Error:
      return 'Export failed';
    case NotificationType.Success:
      return 'Export finished';
    default:
      return 'VideoExport';
  }
}

export function getVideoExportMessage(
  notification: VideoExportNotification
): string {
  const { numberConversions, numberSuccessfulConversions } =
    notification.content.metadata;
  switch (notification.type) {
    case NotificationType.Success: {
      if (numberSuccessfulConversions === '1') {
        return `1 video successfully exported.`;
      }
      return `${numberSuccessfulConversions ?? ''} videos successfully exported.`;
    }
    case NotificationType.Error: {
      if (numberConversions === '1') {
        return `1 video failed to export.`;
      }
      return `${numberConversions ?? ''} videos failed to export.`;
    }
    default:
      return 'VideoExport';
  }
}

export function getImageExportTitle(
  notification: ImageExportNotification
): string {
  switch (notification.type) {
    case NotificationType.Error:
      return 'Export failed';
    case NotificationType.Success:
      return 'Export finished';
    default:
      return 'ImageExport';
  }
}

export function getImageExportMessage(
  notification: ImageExportNotification
): string {
  const { numberConversions, numberSuccessfulConversions } =
    notification.content.metadata;
  switch (notification.type) {
    case NotificationType.Success: {
      if (numberSuccessfulConversions === '1') {
        return `1 image successfully exported.`;
      }
      return `${numberSuccessfulConversions ?? ''} images successfully exported.`;
    }
    case NotificationType.Error: {
      if (numberConversions === '1') {
        return `1 image failed to export.`;
      }
      return `${numberConversions ?? ''} images failed to export.`;
    }
    default:
      return 'ImageExport';
  }
}
